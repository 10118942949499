import { handleActions } from "redux-actions";
import { getLoadingErrorState } from "helpers/reduxHelper";
import { keyBy } from "lodash";

const INITIAL_STATE = {
  customers: getLoadingErrorState([]),
  mills: getLoadingErrorState([]),
  millDetails: getLoadingErrorState([]),
  logiPartnersList: getLoadingErrorState([]),
  runningQualities: getLoadingErrorState([]),
  accountManagers: getLoadingErrorState([]),
  productDevelopers: getLoadingErrorState([]),
  users: getLoadingErrorState({}),
  orgAccountManagers: getLoadingErrorState({}),
  userRoleChangeData: getLoadingErrorState({}),
  customerByID: getLoadingErrorState({}),
  filter: getLoadingErrorState({}),
  search: getLoadingErrorState({}),
  vpaByID: getLoadingErrorState({})
};

const User = handleActions({
  GET_CUSTOMERS_REQUEST: (state, action) => {
    return {
      ...state,
      customers: {
        ...state.customers,
        loading: true,
        error: null,
      }
    }
  },
  GET_CUSTOMERS_SUCCESS: (state, action) => {
    return {
      ...state,
      customers: {
        ...state.customers,
        loading: false,
        error: null,
        data: action.payload,
      }
    }
  },
  GET_CUSTOMER_BY_FID_SUCCESS: (state, action) => {
    return {
      ...state,
      customerByID: {
        ...state.customerByID,
        loading: false,
        error: null,
        data: action.payload.data,
      }
    }
  },
  GET_VPA_SUCCESS: (state, action) => {
    return {
      ...state,
      vpaByID: {
        ...state.vpaByID,
        loading: false,
        error: null,
        data: action.payload.data,
      }
    }
  },
  GET_VPA_FAILURE: (state, action) => {
    return {
      ...state,
      vpaByID: {
        ...state.vpaByID,
        loading: false,
        error: action.payload,
      }
    }
  },
  ADD_VPA_REQUEST:(state, action) => {
    return {
      ...state,
      generatedVPA: {
        loading: true,
      }
    }
  },
  ADD_VPA_SUCCESS:(state, action) => {
    return {
      ...state,
      generatedVPA: {
        loading: false,
      }
    }
  },
  ADD_VPA_FAILURE:(state, action) => {
    return {
      ...state,
      generatedVPA: {
        loading: false,
      }
    }
  },
  UPDATE_GST_REQUEST:(state, action) => {
    return {
      ...state,
      refreshGST: {
        loading: true,
      }
    }
  },
  UPDATE_GST_SUCCESS:(state, action) => {
    return {
      ...state,
      refreshGST: {
        loading: false,
      }
    }
  },
  UPDATE_GST_FAILURE:(state, action) => {
    return {
      ...state,
      refreshGST: {
        loading: false,
      }
    }
  },
  UPDATE_MSME_REQUEST:(state, action) => {
    return {
      ...state,
      msmeRequest: {
        loading: true,
      }
    }
  },
  UPDATE_MSME_SUCCESS:(state, action) => {
    return {
      ...state,
      msmeRequest: {
        loading: false,
      }
    }
  },
  UPDATE_MSME_FAILURE:(state, action) => {
    return {
      ...state,
      msmeRequest: {
        loading: false,
      }
    }
  },
  GET_CUSTOMER_BY_ID_SUCCESS: (state, action) => {
    const customer = Array.isArray(action.payload) ? action.payload[0] : action.payload;

    return {
      ...state,
      customers: {
        ...state.customers,
        loading: false,
        error: null,
        data: state.customers.data.map((c) => {
          if (c.id !== customer.locofastrootId) {
            return c;
          }

          return {
            ...customer,
            id: customer.locofastrootId,
            countArticles: customer.countArticles || c.countArticles,
          };
        }),
      }
    }
  },
  UPDATE_SEARCH_VALUE: (state, action) => {
    return {
      ...state,
      search: {
        value: action.payload,
      }
    }
  },
  UPDATE_FILTER_VALUE: (state, action) => {
    return {
      ...state,
      filter: {
        value: action.payload,
      }
    }
  },
  GET_CUSTOMERS_FAILURE: (state, action) => {
    return {
      ...state,
      customers: {
        ...state.customers,
        loading: false,
        error: action.payload,
      }
    }
  },
  
  GET_LOGI_PARTNERS_REQUEST: (state, action) => {
    return {
      ...state,
      logiPartnersList: {
        ...state.logiPartnersList,
        loading: true,
        error: null,
      }
    }
  },
  GET_LOGI_PARTNERS_SUCCESS: (state, action) => {
    return {
      ...state,
      logiPartnersList: {
        ...state.logiPartnersList,
        loading: false,
        error: null,
        data: action.payload,
      }
    }
  },
  GET_LOGI_PARTNERS_FAILURE: (state, action) => {
    return {
      ...state,
      logiPartnersList: {
        ...state.logiPartnersList,
        loading: false,
        error: action.payload,
      }
    }
  },
  
  GET_RUNNING_QUALITIES_REQUEST: (state, action) => {
    return {
      ...state,
      runningQualities: {
        ...state.runningQualities,
        loading: true,
        error: null,
      }
    }
  },
  GET_RUNNING_QUALITIES_SUCCESS: (state, action) => {
    return {
      ...state,
      runningQualities: {
        ...state.runningQualities,
        loading: false,
        error: null,
        data: action.payload.data,
      }
    }
  },

  GET_RUNNING_QUALITIES_FAILURE: (state, action) => {
    return {
      ...state,
      runningQualities: {
        ...state.runningQualities,
        loading: false,
        error: action.payload,
      }
    }
  },
  
  GET_RUNNING_QUALITIES_FILTERS_REQUEST: (state, action) => {
    return {
      ...state,
      runningQualities: {
        ...state.runningQualities,
        loading: true,
        error: null,
        filters: {}
      }
    }
  },
  GET_RUNNING_QUALITIES_FILTERS_SUCCESS: (state, action) => {
    return {
      ...state,
      runningQualities: {
        ...state.runningQualities,
        loading: false,
        error: null,
        filters: action.payload.data
      }
    }
  },
  GET_RUNNING_QUALITIES_FILTERS_FAILURE: (state, action) => {
    return {
      ...state,
      runningQualities: {
        ...state.runningQualities,
        loading: false,
        error: action.payload,
        filters: {}
      }
    }
  },
  GET_MILLS_REQUEST: (state) => {
    return {
      ...state,
      mills: {
        data: [],
        loading: true,
        error: null,
      }
    }
  },
  GET_MILLS_SUCCESS: (state, action) => {
    return {
      ...state,
      mills: {
        error: null,
        data: [...action.payload.data],
        loading: false,
      }
    }
  },
  GET_MILLS_FAILURE: (state, action) => {
    return {
      ...state,
      mills: {
        data: [],
        loading: false,
        error: action.payload,
      }
    }
  },

  GET_MILL_DETAILS_REQUEST: (state) => {
    return {
      ...state,
      millDetails: {
        data: {},
        loading: true,
        error: null,
      }
    }
  },
  GET_MILL_DETAILS_SUCCESS: (state, action) => {
    return {
      ...state,
      millDetails: {
        error: null,
        data: action.payload.data,
        loading: false,
      }
    }
  },
  GET_MILL_DETAILS_FAILURE: (state, action) => {
    return {
      ...state,
      millDetails: {
        data: {},
        loading: false,
        error: action.payload,
      }
    }
  },

  UPDATE_MILL_DETAILS_SUCCESS: (state, action) => {
    return {
      ...state,
      millDetails: {
        error: null,
        data: action.payload.data,
        loading: false,
      }
    }
  },

  GET_MILLS_REQUEST: (state) => {
    return {
      ...state,
      mills: {
        data: [],
        loading: true,
        error: null,
      }
    }
  },
  GET_MILLS_SUCCESS: (state, action) => {
    return {
      ...state,
      mills: {
        error: null,
        data: [...action.payload.data],
        loading: false,
      }
    }
  },
  GET_MILLS_FAILURE: (state, action) => {
    return {
      ...state,
      mills: {
        data: [],
        loading: false,
        error: action.payload,
      }
    }
  },

  GET_MILL_DETAILS_REQUEST: (state) => {
    return {
      ...state,
      millDetails: {
        data: {},
        loading: true,
        error: null,
      }
    }
  },
  GET_MILL_DETAILS_SUCCESS: (state, action) => {
    return {
      ...state,
      millDetails: {
        error: null,
        data: action.payload.data,
        loading: false,
      }
    }
  },
  GET_MILL_DETAILS_FAILURE: (state, action) => {
    return {
      ...state,
      millDetails: {
        data: {},
        loading: false,
        error: action.payload,
      }
    }
  },

  UPDATE_MILL_DETAILS_SUCCESS: (state, action) => {
    console.log(action)
    return {
      ...state,
      millDetails: {
        error: null,
        data: action.payload.data,
        loading: false,
      }
    }
  },

  GET_ACCOUNT_MANAGERS_REQUEST: (state, action) => {
    return {
      ...state,
      accountManagers: {
        ...state.accountManagers,
        loading: true,
        error: null,
      }
    }
  },
  GET_ACCOUNT_MANAGERS_SUCCESS: (state, action) => {
    return {
      ...state,
      accountManagers: {
        ...state.accountManagers,
        loading: false,
        error: null,
        data: action.payload,
      }
    }
  },
  GET_ACCOUNT_MANAGERS_FAILURE: (state, action) => {
    return {
      ...state,
      accountManagers: {
        ...state.accountManagers,
        loading: false,
        error: action.payload,
      }
    }
  },
  GET_SUPPLIER_MANAGERS_REQUEST: (state, action) => {
    return {
      ...state,
      supplierManagers: {
        ...state.supplierManagers,
        loading: true,
        error: null,
      }
    }
  },
  GET_SUPPLIER_MANAGERS_SUCCESS: (state, action) => {
    return {
      ...state,
      supplierManagers: {
        ...state.supplierManagers,
        loading: false,
        error: null,
        data: action.payload,
      }
    }
  },
  GET_SUPPLIER_MANAGERS_FAILURE: (state, action) => {
    return {
      ...state,
      supplierManagers: {
        ...state.supplierManagers,
        loading: false,
        error: action.payload,
      }
    }
  },

  GET_PRODUCT_DEVELOPERS_REQUEST: (state, action) => {
    return {
      ...state,
      productDevelopers: {
        ...state.productDevelopers,
        loading: true,
        error: null,
      }
    }
  },
  GET_PRODUCT_DEVELOPERS_SUCCESS: (state, action) => {
    return {
      ...state,
      productDevelopers: {
        ...state.productDevelopers,
        loading: false,
        error: null,
        data: action.payload,
      }
    }
  },
  GET_PRODUCT_DEVELOPERS_FAILURE: (state, action) => {
    return {
      ...state,
      productDevelopers: {
        ...state.productDevelopers,
        loading: false,
        error: action.payload,
      }
    }
  },

  GET_ORG_USERS_REQUEST: (state, action) => {
    return {
      ...state,
      users: {
        ...state.users,
        loading: true,
        error: null,
      }
    }
  },
  GET_ORG_USERS_SUCCESS: (state, action) => {
    return {
      ...state,
      users: {
        ...state.users,
        loading: false,
        error: null,
        data: {
          ...state.users.data,
          [action.payload.orgId]: action.payload.data,
        },
      },
    }
  },
  GET_ORG_ACCOUNT_MANAGERS_SUCCESS: (state, action) => {
    return {
      ...state,
      orgAccountManagers: {
        ...state.orgAccountManagers,
        loading: false,
        error: null,
        data: {
          ...state.orgAccountManagers.data,
          [action.payload.orgId]: action.payload.data,
        },
      },
    }
  },
  GET_ORG_USERS_FAILURE: (state, action) => {
    return {
      ...state,
      users: {
        ...state.users,
        loading: false,
        error: action.payload,
      }
    }
  },

  ASSIGN_USERS_TO_ORG_REQUEST: (state, action) => {
    return {
      ...state,
      orgAccountManagers: {
        ...state.orgAccountManagers,
        loading: true,
        error: null,
      }
    }
  },
  ASSIGN_USERS_TO_ORG_SUCCESS: (state, action) => {
    const mapAMs = keyBy(state.accountManagers.data, 'id');
    return {
      ...state,
      orgAccountManagers: {
        ...state.orgAccountManagers,
        loading: false,
        error: null,
        data: {
          ...state.orgAccountManagers,
          [action.payload.orgId]: action.payload.userIds
            .map(userId => mapAMs[userId])  // get the account manager instance from AMs list
            .filter(user => user)           // remove any null/undefined; where somehow AMs were not found in the list
        },
      }
    }
  },
  ASSIGN_USERS_TO_ORG_FAILURE: (state, action) => {
    return {
      ...state,
      orgAccountManagers: {
        ...state.orgAccountManagers,
        loading: false,
        error: action.payload,
      }
    }
  },
  ASSIGN_USERS_TO_TEAM_REQUEST: (state, action) => {
    return {
      ...state,
      assignTeamMember: {
        loading: true,
      }
    }
  },
  ASSIGN_USERS_TO_TEAM_SUCCESS: (state, action) => {
    return {
      ...state,
      assignTeamMember: {
        loading: true,
      }
    }
  },
  ASSIGN_USERS_TO_TEAM_FAILURE: (state, action) => {
    return {
      ...state,
      assignTeamMember: {
        loading: true,
      }
    }
  },

  GET_CURRENCY_RATE_REQUEST: (state, action) => {
    return {
      ...state,
      currencyConversionRate: {
        ...state.currencyConversionRate,
        loading: true,
        error: null,
      }
    }
  },
  GET_CURRENCY_RATE_SUCCESS: (state, action) => {
    return {
      ...state,
      currencyConversionRate: {
        ...state.currencyConversionRate,
        loading: false,
        error: null,
        data: action.payload.data,
      }
    }
  },

  GET_CURRENCY_RATE_FAILURE: (state, action) => {
    return {
      ...state,
      currencyConversionRate: {
        ...state.currencyConversionRate,
        loading: false,
        error: action.payload,
      }
    }
  },

  CREATE_CUSTOMER: (state, action) => {
    const customer = action.payload;

    return {
      ...state,
      customers: {
        ...state.customers,
        loading: false,
        error: null,
        data: state.customers.data.map((c) => {
          if (c.id !== customer.locofastrootId) {
            return c;
          }

          return {
            name: customer.name,
            type: customer.type,
            id: customer.locofastrootId,
            countArticles: c.countArticles,
          };
        }),
      },
    };
  },
  UPDATE_CUSTOMER: (state, action) => {
    const customer = action.payload;

    return {
      ...state,
      customers: {
        ...state.customers,
        loading: false,
        error: null,
        data: state.customers.data.map((c) => {
          if (c.id !== customer.locofastrootId) {
            return c;
          }

          return {
            name: customer.name,
            type: customer.type,
            id: customer.locofastrootId,
            countArticles: c.countArticles,
          };
        }),
      },
    };
  },

  VERIFY_ROLE_CHANGE: (state, action) => {
    return {
      ...state,
      userRoleChangeData: {
        ...state.userRoleChangeData,
        data: action.payload
      }
    }
  },
  ROLE_CHANGE: (state, action) => {
    return {
      ...state,
      userRoleChangeData: {
        ...state.userRoleChangeData,
        isLoading: false
      }
    }
  }
}, INITIAL_STATE);

export default User;
