import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useReducer,
} from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import ReactTooltip from "react-tooltip";
import Downshift from "downshift";
import { connect } from "react-redux";

import { IconMagnifier } from "components/UI/Icons";
import Button from "components/UI/Button/Button";
import CustomInput from "components/UI/Form/Input/CustomInput";
import EmptyScreen from "components/UI/EmptyScreen";
import UserRow from "./UserRow";

import { getLocofastUsers } from "store/actions/AuthActions";
import { assignUsersToTeam } from "store/actions/UserActions";

import "./styles.scss";
import { ReactComponent as TeamIcon } from "assets/images/social/ic-team.svg";
import RemoveMemberIcon from "assets/images/navigation/ic-close.svg";
import AddMemberIcon from "assets/images/content/ic-add.svg";

import { USERS_LIST_FILTERS, EXCLUDE_MAPPED } from "constants/Filters";
import { AuthRoleIds } from "constants/Auth";

const ACTION_ON_USER = {
  Addition: "added",
  Removal: "removed",
  UndoAddition: "undoAddition",
  UndoRemoval: "undoRemoval",
  SetAvailableManagers: "setAvailableManagers",
};

const reducer = (state, action) => {
  const { team, availableManagers, added, removed } = state;
  switch (action.type) {
    case ACTION_ON_USER.Addition: {
      const { user } = action.payload;
      return {
        team: state.team.concat({ ...user, action: action.type }),
        availableManagers: state.availableManagers.filter((manager) => {
          return manager.id !== user.id;
        }),
        removed,
        added: added.concat(user.name),
      };
    }
    case ACTION_ON_USER.Removal: {
      const { index, user } = action.payload;
      if (index === 0) {
        return {
          ...state,
          team: [
            { ...team[0], action: action.type },
            ...team.slice(1, team.length),
          ],
          added,
          removed: removed.concat(user.name),
        };
      }
      return {
        ...state,
        team: [
          ...team.slice(0, index),
          { ...team[index], action: action.type },
          ...team.slice(index + 1, team.length),
        ],
        added,
        removed: removed.concat(user.name),
      };
    }
    case ACTION_ON_USER.UndoRemoval: {
      const { index, user } = action.payload;
      if (index === 0) {
        return {
          ...state,
          team: [{ ...team[0], action: null }, ...team.slice(1, team.length)],
          added,
          removed: removed.filter((manager) => manager !== user.name),
        };
      }
      return {
        ...state,
        team: [
          ...team.slice(0, index),
          { ...team[index], action: null },
          ...team.slice(index + 1, team.length),
        ],
        added,
        removed: removed.filter((manager) => manager !== user.name),
      };
    }
    case ACTION_ON_USER.UndoAddition: {
      const { index, user } = action.payload;
      if (index === 0) {
        return {
          team: team.slice(1, team.length),
          availableManagers: availableManagers.concat({
            ...user,
            action: null,
          }),
          removed,
          added: added.filter((manager) => manager !== user.name),
        };
      }
      return {
        team: [...team.slice(0, index), ...team.slice(index + 1, team.length)],
        availableManagers: availableManagers.concat({ ...user, action: null }),
        removed,
        added: added.filter((manager) => manager !== user.name),
      };
    }
    case ACTION_ON_USER.SetAvailableManagers: {
      const { managers } = action.payload;
      return {
        team,
        added,
        removed,
        availableManagers: managers,
      };
    }
    default:
      return state;
  }
};

const GreyText = ({ text }) => <span className="grey">{text}</span>;
const getMemberChanges = (added, removed) => {
  let base = "";
  if (removed.length > 0) {
    base = (
      <span>
        <GreyText text="Remove" /> <span>{removed.join(", ")}</span>
      </span>
    );
  }
  if (added.length > 0) {
    if (base) {
      base = (
        <span>
          {base} <GreyText text="and" />
        </span>
      );
    }
    base = (
      <span>
        {base} <GreyText text="Add" /> <span>{added.join(", ")}</span>
      </span>
    );
  }
  return base;
};

const ManageMembers = ({
  assignedUsers = [],
  ctaSubmitText = "CONFIRM",
  assignUsersToTeam,
  getLocofastUsers,
  managerId,
  isSupplyHead
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [membersData, dispatch] = useReducer(reducer, {
    team: assignedUsers,
    availableManagers: [],
    added: [],
    removed: [],
  });
  const cleanSearchText = useMemo(() => searchText.trim().toLowerCase(), [
    searchText,
  ]);

  useEffect(() => {
    if (cleanSearchText.length === 0) {
      setFilteredUsers(membersData.availableManagers);
    } else {
      setFilteredUsers(
        membersData.availableManagers.filter((user) =>
          user.name.toLowerCase().includes(cleanSearchText)
        )
      );
    }
  }, [cleanSearchText, membersData.availableManagers]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    const fetchManagers = async () => {
      const availableManagers = await getLocofastUsers(
        [isSupplyHead? AuthRoleIds.SUPPLIER_MANAGER : AuthRoleIds.ACCOUNT_MANAGER],
        {
          [EXCLUDE_MAPPED]: USERS_LIST_FILTERS.excludeMapped,
        }
      );
      dispatch({
        type: ACTION_ON_USER.SetAvailableManagers,
        payload: { managers: availableManagers },
      });
    };

    fetchManagers();
  }, [getLocofastUsers]);

  const searchTextChangeHandler = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const userRemovalHandler = useCallback((user, index) => {
    dispatch({ type: ACTION_ON_USER.Removal, payload: { index, user } });
  }, []);

  const userAdditionHandler = useCallback((user) => {
    dispatch({ type: ACTION_ON_USER.Addition, payload: { user } });
    setSearchText("");
  }, []);

  const userActionUndoHandler = useCallback((user, index) => {
    if (user.action === ACTION_ON_USER.Removal) {
      dispatch({ type: ACTION_ON_USER.UndoRemoval, payload: { user, index } });
    } else if (user.action === ACTION_ON_USER.Addition) {
      dispatch({ type: ACTION_ON_USER.UndoAddition, payload: { user, index } });
    }
  }, []);

  const onSubmit = () => {
    let userIds = [];
    membersData.team.forEach((member) => {
      if (member.action === ACTION_ON_USER.Addition || !member.action) {
        userIds.push(member.id);
      }
    });
    assignUsersToTeam({ managerId, userIds });
  };

  return (
    <div>
      <div className="manage-team-modal__content">
        <div className="manage-team-modal__search">
          <Downshift onChange={searchTextChangeHandler}>
            {({
              getInputProps,
              getToggleButtonProps,
              isOpen,
              getMenuProps,
            }) => {
              return (
                <div {...getToggleButtonProps()} type="none">
                  <span className="flex center" {...getInputProps()}>
                    <CustomInput
                      value={searchText}
                      prefixText={<IconMagnifier />}
                      placeholder="Search by Name"
                      onChange={searchTextChangeHandler}
                      label={<span>Add a member</span>}
                    />
                  </span>

                  {isOpen ? (
                    <div>
                      <div {...getMenuProps()}>
                        <PerfectScrollBar className="manage-team-modal__users-search">
                          {filteredUsers.length === 0 ? (
                            <EmptyScreen
                              description={
                                <div className="no-results">No users found</div>
                              }
                            />
                          ) : (
                            filteredUsers.map((user) => (
                              <UserRow
                                user={user}
                                key={user.id}
                                userSelectHandler={() =>
                                  userAdditionHandler(user)
                                }
                                primaryActionIcon={AddMemberIcon}
                              />
                            ))
                          )}
                        </PerfectScrollBar>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            }}
          </Downshift>
        </div>
        <div className="manage-team-modal__users-count">
          Team members <span className="count">{membersData.team.length}</span>
        </div>

        <PerfectScrollBar className="manage-team-modal__users-list">
          {membersData.team.length === 0 ? (
            <div className="empty-state">
              <TeamIcon />
              <div>Search and add team members</div>
            </div>
          ) : (
            membersData.team.map((user, index) => (
              <UserRow
                user={user}
                key={user.id}
                index={index}
                userSelectHandler={() => userRemovalHandler(user, index)}
                undoHandler={() => userActionUndoHandler(user, index)}
                primaryActionIcon={RemoveMemberIcon}
              />
            ))
          )}
        </PerfectScrollBar>
      </div>
      <div className="manage-team-modal__footer">
        <div className="changes">
          {getMemberChanges(membersData.added, membersData.removed)}
        </div>
        <Button
          className="cta-submit"
          category="blue-bg"
          disabled={
            membersData.added.length === 0 && membersData.removed.length === 0
          }
          onClick={onSubmit}
        >
          {ctaSubmitText}
        </Button>
      </div>
    </div>
  );
};
export default connect(null, {
  getLocofastUsers,
  assignUsersToTeam,
})(ManageMembers);
