import React from "react";

import Downshift from "downshift";
import PerfectScrollbar from "react-perfect-scrollbar";

import { IconPlus } from "components/UI/Icons";
import Button from "../../UI/Button/Button";
import { ALL_LF_ROLES } from "constants/Filters";

import icDropdown from "./../../../assets/images/navigation/ic-arrow-drop-down.svg";

const HeaderWithFilters = ({
  onChange,
  addLfUser,
  linkZohoToUser,
  getLocofastUsers,
  openModal,
  loggedInUserDetails,
}) => {
  const handleClick = () => {
    openModal("ADD_LF_USER", {
      userData: { brandId: loggedInUserDetails.brandId },
      addLfUser,
      linkZohoToUser,
      afterSubmit: getLocofastUsers,
      canEditRole: true
    });
  };

  const initialSelectedItem = filterOptions[0];
  return (
    <div className="top-bar lfusers-header flex center justify-between">
      <div className="tab-title">Users</div>
      <div className="filter">
        <Downshift
          initialSelectedItem={initialSelectedItem}
          itemToString={(item) => item.label}
          onChange={(selection) => {
            onChange([selection.value]);
          }}
        >
          {({
            getInputProps,
            getToggleButtonProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
          }) => {
            return (
              <div
                {...getToggleButtonProps()}
                type="none"
                className="filter__toggle"
              >
                <span {...getLabelProps()} className="filter__label">
                  User:
                </span>
                <span className="flex center">
                  <input
                    className="filter__input"
                    readOnly={true}
                    disabled={true}
                    {...getInputProps()}
                  />
                  <img src={icDropdown} alt="dropdown" />
                </span>

                {isOpen ? (
                  <div
                    className="select-pane"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div>
                      <PerfectScrollbar>
                        {filterOptions
                          // .filter(item => item.label !== selectedItem.label)
                          .map((item, index) => {
                            return (
                              <div
                                {...getItemProps({
                                  className: `in-selection collection-collab__label_left_aigned flex ${
                                    selectedItem.label == item.label
                                      ? "selected"
                                      : ""
                                  }`,
                                  index,
                                  item,
                                  key: item.label,
                                })}
                              >
                                {item.label}
                              </div>
                            );
                          })}
                      </PerfectScrollbar>
                    </div>
                  </div>
                ) : null}
              </div>
            );
          }}
        </Downshift>
        <Button
          category="grid-2 blue-bg"
          onClick={handleClick}
          type="button"
          className="add-user-btn"
        >
          <IconPlus color="white" />
          Add User
        </Button>
      </div>
    </div>
  );
};

const filterOptions = [
  {
    label: "All Users",
    value: ALL_LF_ROLES,
  },
  {
    label: "Account Manager",
    value: "12",
  },
  {
    label: "Supplier Manager",
    value: "13",
  },
  {
    label: "City Head",
    value: "14",
  },
  {
    label: "Logistics Manager",
    value: "15",
  },
  {
    label: "Supply Head",
    value: "16",
  },
];

export default HeaderWithFilters;
