import React, { useState, useEffect } from 'react';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Button, Modal, Select } from 'antd';
import * as qs from 'query-string';
import * as Yup from 'yup';

import './styles.scss';

import icNavLeft from './../../../../assets/images/navigation/ic-chevron-left.svg';
import { connect } from 'react-redux';
import {
  changeMillStatus,
  changeLCVStatus,
  getMillDetails,
  getMills,
  deactivateMill,
  changeDirectInvoiceStatus,
  updateAllocatedSM as updateAllocatedSMAction,
} from 'store/actions/UserActions';
import { DeactivateMillModal } from './EditMillModal';
import Sections from './MillDetailSections';
import { getLocofastUsers as getLocofastUsersAction } from 'store/actions/AuthActions';
import StatusToggler from 'components/UI/Toggler/StatusToggler';
import { flash } from 'store/actions/CommonActions';
import FieldInput from 'components/AccountSettings/TopQualities/FieldInput';
import { Formik } from 'formik';
import { UPTO_TWO_DECIMAL_REGEX } from 'components/AccountSettings/Customers/CustomerDetailsPage/AnnualTurnover/config';
import { CATEGORY_TYPES } from '../constants';

const EditMill = ({
  initialValues = {},
  mills,
  getMills,
  getMillDetails,
  millDetails,
  match,
  changeMillStatus,
  changeLCVStatus,
  deactivateMill,
  changeDirectInvoiceStatus,
  getLocofastUsers,
  updateAllocatedSM,
  lfUsers,
  showErrorMessage,
  ...restProps
}) => {
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [editSM, setEditSM] = useState('');
  const [SMDetails, setSMDetails] = useState({});
  const [contactPerson, setContactPerson] = useState('');
  const [millData, setMillData] = useState({});
  const [isNotFoundError, setIsNotFoundError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCommissionSlabVisible, setIsCommissionSlabVisible] = useState(false);
  const id = match.params.id;
  const { Option } = Select;
  const queryParams = qs.parse(restProps.location.search);
  const invoiceStatus = millData?.isDirectInvoicing;
  const { supplierCommission } = millData || {};
  const cottonValue = supplierCommission?.[0]?.percentage;
  const rayonValue = supplierCommission?.[1]?.percentage;
  const validationSchema = Yup.object().shape({
    cottonPercentage: Yup.number()
      .test('maxDigitsAfterDecimal', 'Maximum decimal alowed is 2', (number) =>
        UPTO_TWO_DECIMAL_REGEX.test(number),
      )
      .max(20, 'Commission cannot be greater than 20%'),
    rayonPercentage: Yup.number()
      .test('maxDigitsAfterDecimal', 'Maximum decimal alowed is 2', (number) =>
        UPTO_TWO_DECIMAL_REGEX.test(number),
      )
      .max(20, 'Commission cannot be greater than 20%'),
  });
  const handleActivate = () => {
    if (millData?.isActive === 'Y') {
      setDeactivateModal(!deactivateModal);
    } else {
      deactivateMill({
        supplierId: millData?.supplierId.toString(),
        newStatus: 'activate',
      });
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCloseCommisionSlab = () => {
    setIsModalVisible(false);
  };
  const handleAddCommissionSlab = (values) => {
    changeDirectInvoiceStatus({
      supplierId: millData?.supplierId.toString(),
      params: {
        isDirectInvoicing: 1,
        supplierCommission: [
          {
            categoryId: CATEGORY_TYPES.Cotton,
            percentage: values.cottonPercentage.toString(),
          },
          {
            categoryId: CATEGORY_TYPES.Rayon,
            percentage: values.rayonPercentage.toString(),
          },
        ],
      },
      invoiceStatus,
    });
    setIsModalVisible(false);
    if (isCommissionSlabVisible) {
      setIsCommissionSlabVisible(false);
    }
  };
  const handleStatusChange = (isOnline) => {
    const status = isOnline ? 1 : 0;
    const supplierId = millData?.supplierId;
    changeMillStatus({ supplierId, status });
  };

  const handleEditSM = (data) => {
    setSMDetails(data);
    setEditSM(data.id);
  };

  const handleUpdateSM = (data) => {
    const fabricCategoryId = SMDetails?.category[0]?.id;
    const managerId = SMDetails?.id;
    const supplierId = millData?.supplierId;
    updateAllocatedSM({ fabricCategoryId, managerId, supplierId });
    setEditSM('');
  };

  const handleLCVChange = (status, annualTurnover) => {
    const isCertified = status ? 1 : 0;
    const supplierId = millData?.supplierId;

    if (annualTurnover || !status) {
      changeLCVStatus({ supplierId, isCertified });
    } else {
      showErrorMessage('Please upload annual turnover details');
    }
  };
  const handleInvoiceChange = () => {
    const invoiceStatus = millData?.isDirectInvoicing;
    if (!invoiceStatus) {
      showModal();
    } else {
      changeDirectInvoiceStatus({
        supplierId: millData?.supplierId.toString(),
        params: { isDirectInvoicing: 0 },
        invoiceStatus,
      });
    }
  };
  useEffect(() => {
    if (millDetails?.data.id) {
      setIsNotFoundError('');
      setContactPerson(millDetails?.data.firstName + ' ' + (millDetails?.data.lastName || ''));
      setMillData(millDetails?.data);
    }
    if (millDetails?.error) {
      setIsNotFoundError(millDetails?.error);
    }
  }, [millDetails]);

  useEffect(() => {
    setMillData({});
    getMillDetails(id);
    getLocofastUsers(['13']);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { annualTurnover = null } = millData;
  return (
    <div className="factory-add-edit-container">
      <PerfectScrollBar style={{ maxHeight: '900px' }}>
        <div className="factory-container">
          <header>
            <div className="navContainer">
              <div className="flex-column">
                <div className="mill-heading">Mill Details</div>
                <div className="leftNavBtns">
                  <Link
                    className="nav-left"
                    to={`../${
                      queryParams.search
                        ? `?search=${queryParams.search}&page=${queryParams.page}`
                        : ''
                    }`}>
                    <img src={icNavLeft} alt="nav-left" />
                  </Link>
                  <div className="mill-heading-details">
                    <h4>{millData?.companyName}</h4>
                    <p>{millData?.alias}</p>

                    {millData?.deactivationReason && (
                      <p className="red-message">
                        Deactivation Reason: {millData?.deactivationReason}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="rightNavBtns">
                <div className="mt-5">
                  {millData?.supplierManager?.map((sm) => (
                    <div className="mr-1">
                      <span>
                        {sm?.fabricCategory?.map((data) => data.category + ' ')}: {sm?.name + ' '}
                      </span>
                      <button
                        className="millEditBtn"
                        onClick={() =>
                          handleEditSM({
                            id: sm.id,
                            category: sm?.fabricCategory,
                          })
                        }>
                        edit
                      </button>
                    </div>
                  ))}
                </div>
                <div className="millDetailsToggles">
                  <StatusToggler
                    label={'Mill Status'}
                    condition={millData?.isOnline}
                    onFalseLabel={'Offline'}
                    onTrueLabel={'Online'}
                    onChange={handleStatusChange}
                  />
                  <StatusToggler
                    label={'Locofast Certified Vendor'}
                    condition={millData?.isCertified}
                    onFalseLabel={'No'}
                    onTrueLabel={'Yes'}
                    onChange={(status) => handleLCVChange(status, annualTurnover)}
                  />
                  <StatusToggler
                    label={'Status'}
                    condition={millData?.isActive === 'Y'}
                    onFalseLabel={'Deactivate'}
                    onTrueLabel={'Activate'}
                    onChange={() => handleActivate()}
                  />
                </div>
                <div className="flex">
                  {invoiceStatus ? (
                    <div
                      className="millEditBtn mr-6"
                      onClick={() => setIsCommissionSlabVisible(true)}>
                      View commission slab
                    </div>
                  ) : (
                    ''
                  )}
                  <StatusToggler
                    label={'Direct Invoicing'}
                    condition={Boolean(millData?.isDirectInvoicing)}
                    onFalseLabel={'No'}
                    onTrueLabel={'Yes'}
                    onChange={handleInvoiceChange}
                  />
                </div>
              </div>
            </div>
          </header>

          {isNotFoundError || !millData?.id ? (
            <section className="details-card details-error">
              <div className="error">Mill doesn't exist !!</div>
            </section>
          ) : (
            <>
              <DeactivateMillModal
                visible={deactivateModal}
                toggle={setDeactivateModal}
                data={millData}
                action={deactivateMill}
              />
              <Sections millData={millData} contactPerson={contactPerson} />
            </>
          )}
        </div>
      </PerfectScrollBar>
      <div>
        <Modal
          title={'Select New Supplier Manager'}
          visible={editSM}
          // onOk={handleSubmit}
          onCancel={() => setEditSM('')}
          okButtonProps={{ disabled: true }}
          cancelButtonProps={{ disabled: false }}
          footer={[
            <Button key="back" onClick={() => setEditSM('')}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              role="submit"
              onClick={handleUpdateSM}
              disabled={editSM == SMDetails?.id}>
              Update
            </Button>,
          ]}>
          <>
            <h3>
              Category:{' '}
              {SMDetails?.category?.map((data) => (
                <span>{data.category + ' '}</span>
              ))}
            </h3>
            <Select
              placeholder="Select SM"
              optionFilterProp="children"
              showSearch={true}
              value={SMDetails.id}
              onChange={(e) => setSMDetails({ ...SMDetails, id: e })}
              className="dropdown-select">
              {lfUsers?.length &&
                lfUsers.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {`${user.firstName} ${user.lastName}`}
                  </Option>
                ))}
            </Select>
          </>
        </Modal>
        <Modal
          title={`${invoiceStatus ? 'Edit' : 'Add'} commission slab`}
          visible={isModalVisible}
          onCancel={handleCloseCommisionSlab}
          className="supplier-modal"
          destroyOnClose
          footer={null}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={
              invoiceStatus
                ? {
                    cottonPercentage: cottonValue,
                    rayonPercentage: rayonValue,
                  }
                : { cottonPercentage: '', rayonPercentage: '' }
            }
            onSubmit={(values) => {
            }}>
            {({ errors, dirty, values }) => {
              const isButtonDisabled = !dirty || (dirty && Object.keys(errors).length > 0);
              return (
                <>
                  <div>
                    <FieldInput
                      isRequired
                      name="cottonPercentage"
                      label="Greige Cotton"
                      placeholder="Enter Cotton percentage"
                      type="number"
                      isUnit="%"
                    />
                    <div className="mt-8">
                      <FieldInput
                        isRequired
                        name="rayonPercentage"
                        label="Greige Rayon"
                        placeholder="Enter Rayon percentage"
                        type="number"
                        isUnit="%"
                      />
                    </div>
                  </div>
                  <div className="footer">
                    <Button key="back" onClick={handleCloseCommisionSlab} className="clear">
                      Close
                    </Button>
                    <Button
                      key="submit"
                      type="primary"
                      role="submit"
                      className={`apply ${isButtonDisabled && 'disabledButton'}`}
                      onClick={() => handleAddCommissionSlab(values)}
                      disabled={isButtonDisabled}>
                      {invoiceStatus ? 'Update' : 'Submit'}
                    </Button>
                  </div>
                </>
              );
            }}
          </Formik>
        </Modal>
        <Modal
          title={'Commission slab'}
          visible={isCommissionSlabVisible}
          onCancel={() => setIsCommissionSlabVisible(false)}
          className="supplier-modal"
          destroyOnClose
          footer={null}>
          <div>
            <div className="flex justify-between">
              <div>Greige Cotton</div>
              <div>{`${cottonValue}%`}</div>
            </div>
            <div className="flex justify-between mt-12">
              <div>Greige Rayon</div>
              <div>{`${rayonValue}%`}</div>
            </div>
            <div className="footer">
              <Button
                key="back"
                onClick={() => setIsCommissionSlabVisible(false)}
                className="clear">
                Close
              </Button>
              <Button
                key="submit"
                type="primary"
                role="submit"
                className="apply"
                onClick={() => setIsModalVisible(true)}>
                Edit
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  mills: props.users || state.user.mills,
  millDetails: state.user.millDetails,
  lfUsers: state.auth.lfUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getMills: () => dispatch(getMills()),
  showErrorMessage: (message) => dispatch(flash({ message })),
  getMillDetails: (id) => dispatch(getMillDetails(id)),
  changeMillStatus: (data) => dispatch(changeMillStatus(data)),
  changeLCVStatus: (data) => dispatch(changeLCVStatus(data)),
  deactivateMill: (data) => dispatch(deactivateMill(data)),
  changeDirectInvoiceStatus: (data) => dispatch(changeDirectInvoiceStatus(data)),
  getLocofastUsers: (data) => dispatch(getLocofastUsersAction(data)),
  updateAllocatedSM: (data) => dispatch(updateAllocatedSMAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMill);
