import axios from "axios";
import actions from "./store/actions";

const MAP_NON_SUCCESS_STATUS_CODES = {
  400: true,
  401: true,
  403: true,
  404: true,
  500: true,
  502: true,
  503: true,
  504: true,
  422: true,
  413: true,
  498: true,
};

/* Default axios config
 */
// Set to true for using CORS cookie credentials automatically.
axios.defaults.withCredentials = true;

export default {
  setupInterceptors: store => {
    axios.interceptors.request.use(request => {
      // Add trailing slash to request url, if not present already.
      if (request.url[request.url.length - 1] !== "/") {
        request.url += "/";
      }

      // console.log("HEY THERE ", request);

      // Log every request in redux which uses Axios.
      store.dispatch(
        actions.REQUEST_API({
          url: request.url
        })
      );

      if (request.params && !request.params.isLoader && !request.params.inlineLoader) {
        store.dispatch(actions.isLoading());
      }

      if (!request.params) {
        store.dispatch(actions.isLoading());
      }

      if(request.params && request.params.inlineLoader){
        delete request.params.inlineLoader;
      }


      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        request.headers.Authorization = `bearer ${accessToken}`;
      }

      return request;
    });

    axios.interceptors.response.use(
      response => {
        store.dispatch(actions.finishedLoading());

        const httpStatusCode = response.statusCode;
        const statusCode = response?.data?.resp?.code;

        // let message = "";

        // if (statusCode === 404) {
        //   store.dispatch(
        //     actions.flash({
        //       type: "error",
        //       message: response.data.resp.msg,
        //       duration: 3000
        //     })
        //   );
        // }

        if (
          MAP_NON_SUCCESS_STATUS_CODES[statusCode] || MAP_NON_SUCCESS_STATUS_CODES[httpStatusCode]
        ) {
          // switch (statusCode) {
          //   case 401:
          //     message =
          //       "Unauthorized: Please try to login before accessing this information.";
          //     break;
          //   case 403:
          //     message =
          //       "Forbidden: You do not have sufficient permissions to access this information.";
          //     break;
          //   case 502:
          //     message =
          //       "Bad Gateway: Server error, please try again after some time.";
          //     break;
          //   case 503:
          //     message =
          //       "Service unavailable: Servers are busy right now please reload after some time.";
          //     break;
          //   case 504:
          //     message =
          //       "Gateway Timeout: Servers are unable to reply, please try again after some time. ";
          //     break;
          //   default:
          //     message = "Server Error: Please try again after some time";
          //     break;
          // }

          // For proper logout if unauthorized
          // if (statusCode === 403) {
          // TODO: HACK: Hard coded message for cookie expiration because backend is sending 403 for other things as well.
          if (
            (statusCode === 401 || httpStatusCode === 401)
            || response.data?.resp?.msg === "Please login again! Our system didn't recognize you."
          ) {
            store.dispatch(actions.LOGOUT());
          }

          store.dispatch(
            actions.flash({
              type: "error",
              message: response.data?.resp?.msg,
              duration: 3000
            })
          );
          response.status = statusCode;
          // Reject the request if there's an error found
          return Promise.reject(response);
        }

        // TODO: Remove line when data is converted plain JSON
        if (response.data?.resp?.data !== undefined)
          response.data = response.data?.resp?.data;
        else if (response.data?.resp?.list !== undefined)
          response.data = response.data?.resp?.list;

        // console.log(response.data);

        // if (statusCode === 403) response.data = {};
        return response;
      },
      error => {

        if (error.response && error.response.status === 401) {
          store.dispatch(actions.LOGOUT());
          store.dispatch(
            actions.flash({
              type: "error",
              message:
                "Please login again.",
              duration: 2000
            })
          );
        } else {
          store.dispatch(actions.finishedLoading());
          store.dispatch(
            actions.flash({
              type: "error",
              message:
                error?.response?.data?.message ? error.response.data.message : "Something went wrong, please refresh this page to see accurate data.",
              duration: 2000
            })
          );
        }

        console.dir(error);
        // Force reloading + bypass cache (true) when a Network Error occurs
        // setTimeout(() => window.location.reload(true), 700);

        return Promise.reject(error);
      }
    );
  }
};
