import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CLIENT, LOCO_SUPER, CLIENT_SUPER, FACTORY_ADMIN, FACTORY_MANAGER, FABRIC_SUPPLIER, BDE, PRODUCT_DEVELOPMENT, PRODUCTION_MERCHANT, ACCOUNT_MANAGER, SUPPLIER_MANAGER } from "./../../../constants/Auth";

const PrivateRoute = ({
  loggedIn,
  userRoles = [CLIENT, LOCO_SUPER, CLIENT_SUPER, FACTORY_ADMIN, FACTORY_MANAGER, FABRIC_SUPPLIER, BDE, PRODUCT_DEVELOPMENT, PRODUCTION_MERCHANT, ACCOUNT_MANAGER, SUPPLIER_MANAGER],
  render: Render,
  location,
  ...rest
}) => {
  const currentUserRole = localStorage.getItem("role");

  const CONDITIONS_RESULT = [
    loggedIn === true,
    userRoles.includes(currentUserRole)
  ].reduce((acc, currVal) => acc && currVal);

  const fullUrl = encodeURIComponent(`${location.pathname}${location.search}${location.hash}`);
  const redirectUrl = fullUrl ? `?redirect=${fullUrl}` : '';

  return (
    <Route
      {...rest}
      render={props =>
        CONDITIONS_RESULT ? Render(props) : <Redirect to={`/auth/login/${redirectUrl}`} />
      }
    />
  );
};

PrivateRoute.propTypes = {};

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn
});

export default withRouter(connect(
  mapStateToProps,
  null
)(PrivateRoute));
